.wrapper {
  /* containing width and center content*/
  max-width: 1440px;
  margin: 0 auto;
}

@media (min-width: 991px) {
  /* padding on desktop */
  .wrapper {
    padding-left: 48px;
    padding-right: 48px;
  }
}
@media (max-width: 991px) {
  /* padding on mobile */
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
